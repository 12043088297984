<div class="wrapper" #mapWrapper aria-hidden="true" role="none" tabindex="-1">
  <google-map
    *ngIf="apiLoaded"
    id="map"
    width="100%"
    height="100%"
    [center]="center"
    tabindex="-1"
    [zoom]="zoomLevel"
    [options]="{
      scrollwheel: false,
      keyboardShortcuts: false,
      maxZoom: MAX_MAP_ZOOM,
      gestureHandling: this.static ? 'none' : 'greedy',
      disableDefaultUI: true,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }]
        },
      ],
    }"
    (mapInitialized)="bootstrapMap($event)">
    <app-custom-marker
      *ngIf="showGpsLocation && userLocation?.lat && userLocation.lng && nativeMap"
      [location]="userLocation"
      [map]="nativeMap"
      [data]="defaultUserLocationData"
      [tabIndex]="tabIndexPosition + 2"
    >
      <app-img src="assets/drrweb-lib/images/gps-dot.png"></app-img>
    </app-custom-marker>
    <ng-container *ngFor="let customMarker of customMarkers">
      <app-custom-marker
        #customMarkerComponents
        id="{{customMarker.id}}"
        [location]="customMarker.location"
        [map]="nativeMap"
        (markerTouchStart)="handleCustomMarkerClick(customMarker)"
        [data]="customMarker.markerDetails"
        [tabIndex]="customMarker.tabIndex">
        <app-map-icon [markerDetails]="customMarker.markerDetails"></app-map-icon>
      </app-custom-marker>
    </ng-container>
  </google-map>

  <ng-container *ngIf="centerMarker">
    <app-map-icon class="center-marker" [markerDetails]="centerMarker"></app-map-icon>
  </ng-container>

  <div class="right-side-slot">
    <div *ngIf="displayZoomAction">
      <aaa-button
        size="medium"
        class="zoom-in drr-map-button ion-float-right"
        variant="on-map"
        i18n-aria-label
        aria-label="zoom in"
        (click)="zoomIn($event)"
        [tabIndex]="tabIndexPosition + 5">
        <aaa-icon name="add-outline"></aaa-icon>
      </aaa-button>
      <aaa-button
        size="medium"
        class="zoom-out drr-map-button ion-float-right ion-margin-8"
        variant="on-map"
        i18n-aria-label
        aria-label="zoom out"
        (click)="zoomOut($event)"
        [tabIndex]="tabIndexPosition + 5">
        <aaa-icon name="remove-outline"></aaa-icon>
      </aaa-button>
    </div>
    <div *ngIf="displayLocateMeAction">
      <aaa-button
        id="locate-user-button"
        size="medium"
        class="drr-map-button ion-float-right ion-margin-top"
        variant="primary"
        i18n-aria-label
        aria-label="locate user"
        (click)="locateUser($event)"
        [tabIndex]="tabIndexPosition + 5">
        <aaa-icon name="navigate-outline"></aaa-icon>
      </aaa-button>
    </div>
  </div>

  <div class="top-slot">
    <ng-content select="[topSlot]"></ng-content>
  </div>

  <div class="bottom-slot">
    <ng-content select="[bottomSlot]"></ng-content>
  </div>
</div>
