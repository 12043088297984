import { Injectable } from '@angular/core'
import { Adapter } from '../types'
import { AAACallData } from '../../modules/dashboard/calls.types'
import { getCookie } from '../utils/cookies'
import { CHARACTER_LIMIT_COMMENTS, CHARACTER_LIMIT_COMMENTS_RAP } from '../../modules/dashboard/calls.constants'
import { RapService } from '../../modules/rap/rap.service'
import { VehicleAdapter } from "./VehicleAdapter";
import { unmaskNumericField } from '../../modules/ui/ui.utils'

@Injectable({
  providedIn: 'root',
})
export class AAACallDataAdapter implements Adapter<AAACallData> {

  constructor(
    private rapService: RapService,
    private vehicleAdapter: VehicleAdapter,
  ) {}

  adapt(data: AAACallData): AAACallData {
    const appid = getCookie('AAA_AppId')
    const characterLimitComments = this.rapService.isRapUser() ? CHARACTER_LIMIT_COMMENTS_RAP : CHARACTER_LIMIT_COMMENTS
    const comments = `DRRWeb 2.0 ${appid} ${data.channel || ''} ${data.comments || ''}`
      .replace(/  +/g, ' ')
      .trim()
      .substring(0, characterLimitComments)

    const breakDownStAddress = `${
      data?.breakdownLocation?.streetNumber || ''
    } ${data?.breakdownLocation?.street || ''}`.trim()

    const towingStAddress = `${data?.towing?.streetNumber || ''} ${
      data?.towing?.streetName || ''
    }`.trim()

    if (data.towing) {
      data.towing.city = data.towing.city ? data.towing.city.toUpperCase() : ''
    }
    if (data.breakdownLocation) {
      data.breakdownLocation.city = data.breakdownLocation.city
        ? data.breakdownLocation.city.toUpperCase()
        : ''
    }

    return {
      ...data,
      breakdownLocation: {
        ...data.breakdownLocation,
        streetAddress: breakDownStAddress,
      },
      ...(data.contactInfo && {
        contactInfo: {
          ...data.contactInfo,
          phone: unmaskNumericField(data.contactInfo.phone)
        }
      }),
      ...(data.towing && {
        towing: {
          ...data.towing,
          streetAddress: towingStAddress,
          name: data.towing.landmark ? data.towing.landmark : data.towing.name,
        },
      }),
      comments,
      vehicle: this.vehicleAdapter.adaptCallVehicle(data.vehicle),
    }
  }
}
